$primary: #B91E32;
//$body-bg: #f2f2f2;
$body-color: #444;
$spinner-border-width-sm: .1em;
$spinner-animation-speed: .5s;
$breadcrumb-divider-color: var(--bs-gray-500);
$breadcrumb-divider: quote("\203A");

//@import "../node_modules/bootstrap/scss/bootstrap";

@import '~bootstrap/scss/bootstrap';

.btn-whatsapp {
    --bs-btn-bg: #25D366;
    --bs-btn-border-color: #{rgba($white, .25)};
    --bs-btn-hover-bg: #{darken(mix($gray-600, #25D366, .5), 5%)};
    --bs-btn-hover-border-color: #{rgba($white, .25)};
    --bs-btn-active-bg: #{darken(mix($gray-600, #25D366, .5), 10%)};
    --bs-btn-active-border-color: #{rgba($white, .5)};
    --bs-btn-focus-border-color: #{rgba($white, .5)};
    --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
}